.attendance-container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  min-height: 100vh;
  background-color: #151f28;
  text-align: center;
  font-family: Arial, sans-serif;
  padding: 22px;
}

.attendance-main {
  display: flex;
  flex-direction: column;
}

.attendance-item {
  border: 1px solid #151f28;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 15px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

.attendance-item h3 {
  text-align: center;
  margin: 0;
  margin-bottom: 10px;
}

.attendance-item ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: center;
}

.attendance-item li {
  display: inline-block;
  margin: 5px 0;
  padding: 5px;
  border-bottom: 1px solid #ddd;
}

.attendance-times-label {
  font-weight: bold;
  text-align: center;
  margin-bottom: 15px;
  color: #fff;
}

.date-selector {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.date-selector label {
  margin-bottom: 10px;
  margin-top: 10px;
  color: #fff;
}

.date-selector select,
.date-selector input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.student-table {
  margin-top: 10px;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}

.student-table th,
.student-table td {
  border: 1px solid #151f28; /* Cor da borda */
  padding: 5px;
  text-align: center;
}

.schedule-table {
  margin-top: 10px;
  width: 100%;
  border-collapse: collapse;
}

.schedule-table th,
.schedule-table td {
  border: 1px solid #151f28; /* Cor da borda */
  padding: 5px;
  text-align: center;
}

.schedule-table th {
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}

.student-table th {
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}

.search-input {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #151f28;
  font-size: 1rem;
  width: 100%;
  margin-top: 20px;
}
