.dashboard-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: #151f28;
  text-align: center;
  font-family: Arial, sans-serif;
  padding: 22px;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.dashboard-header h1 {
  font-size: 2rem;
  margin: 0;
}

.card-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.button-bar {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-bottom: 20px;
}

.icon {
  cursor: pointer;
  font-size: 24px;
  padding: 10px;
}

.logout-button {
  background: #fff;
  border: none;
  border-radius: 50px;
  color: #151f28;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  padding: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 5px;
}

.card {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid #151f28;
  border-radius: 10px;
  flex: 1 1 calc(33.333% - 20px);
  min-width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Distribute space */
  padding: 5px;
  box-sizing: border-box;
  text-decoration: none; /* Remove underline from link */
  color: inherit; /* Inherit color from parent */
  /* transition: background-color 0.3s ease; */
}

.card-link {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.card-content {
  text-align: center;
  flex: 1; /* Allow content to grow and fill available space */
}

.card-icon {
  margin-top: 10px;
  font-size: 1.5rem;
  color: #fff; /* Cinza para os ícones */
  margin-bottom: 10px;
}

.card-content h2 {
  font-size: 1.1rem;
  margin: 0;
  color: #fff; /* Cinza para o texto */
}

.card-footer {
  text-align: center;
  margin-top: 10px;
}

.card-line {
  border: none;
  border-top: 1px solid #151f28;
  margin: 5px 0;
}

.card-text {
  color: #fff; /* Cinza para o texto "Acessar" */
  font-size: 0.9rem;
}

.card:hover {
  /* background-color: #f8f9fa; */
  cursor: pointer; /* Show pointer cursor on hover */
}

.logout-icon {
  background: #151f28;
  color: #fff;
  border: none;
  border-radius: 50px;
}
