.bio-links-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #151f28;
  text-align: center;
  font-family: Arial, sans-serif;
  padding: 0 20px;
}

.header {
  position: absolute;
  top: 40px;
  right: 40px;
}

.menu-icon {
  cursor: pointer;
  color: white;
  font-size: 1.8rem;
}

.menu {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: #151f28;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.admin-button {
  display: block;
  padding: 10px 20px;
  color: #ffffff;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.admin-button:hover {
  background-color: #e0e0e0;
  color: #151f28;
}

.logo {
  width: 280px;
  height: auto;
  margin-bottom: 50px;
}

h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: white;
}

p {
  color: white;
  margin-bottom: 30px;
}

.links a {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
  padding: 10px 50px;
  text-decoration: none;
  background-color: white;
  color: #151f28;
  border-radius: 100px;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.links a svg {
  font-size: 1rem;
  margin-right: 10px;
}

.links a:hover {
  background-color: #e0e0e0;
}

.footer {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  text-align: center;
  color: white;
  font-size: 0.9rem;
}

.follow-me {
  font-size: 1rem;
}
