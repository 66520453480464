.payment-container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  min-height: 100vh;
  background-color: #151f28;
  text-align: center;
  font-family: Arial, sans-serif;
  padding: 22px;
}

.payment-main {
  display: flex;
  flex-direction: column;
}

.payment-item {
  border: 1px solid #151f28;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 15px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

.payment-item h3 {
  text-align: center;
  margin: 0;
  margin-bottom: 10px;
}

.date-selector {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.date-selector label {
  margin-bottom: 10px;
  margin-top: 10px;
  color: #fff;
}

.date-selector select,
.date-selector input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.search-input {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #151f28;
  font-size: 1rem;
  width: 100%;
  margin-top: 20px;
}

.payment-table {
  margin-top: 10px;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}

.payment-table th,
.payment-table td {
  border: 1px solid #151f28; /* Cor da borda */
  padding: 5px;
  text-align: center;
}

.payment-table th {
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}

.value-inputs {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.value-inputs label {
  color: white;
  margin-right: 10px;
  margin-bottom: 20px;
}

.value-inputs input {
  width: 100%;
  margin-right: 20px; /* Adiciona espaço entre os inputs */
  line-height: 0.5; /* Ajusta a altura da linha para centralizar verticalmente */
  padding: 10px; /* Adiciona um pouco de preenchimento */
  text-align: center; /* Centraliza o texto horizontalmente */
}

.day-select {
  width: 20px; /* Defina a largura que você deseja */
  max-width: 20px; /* Evita que o select expanda além de 60px */
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  overflow: hidden; /* Esconde o conteúdo que ultrapassa */
  text-overflow: ellipsis; /* Adiciona reticências ao texto que não cabe */
  white-space: nowrap; /* Impede a quebra de linha */
}

.day-select option {
  width: 20px; /* Ajuste se necessário */
  white-space: nowrap; /* Impede a quebra de linha nas opções */
}

.classes-values {
  align-items: center;
}

.unpayment-table {
  margin-top: 10px;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}

.unpayment-table th,
.unpayment-table td {
  border: 1px solid #151f28; /* Cor da borda */
  padding: 5px;
  text-align: center;
}

.unpayment-table th {
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}

.values-title,
.clients-title {
  font-size: 1.1rem; /* Tamanho da fonte */
  font-weight: bold; /* Negrito */
  color: #fff; /* Cor do texto */
  margin: 20px 0; /* Margem superior e inferior */
  text-align: center; /* Alinhamento do texto */
}

/* Estilo específico para "Valores das aulas" */
.values-title {
  border-bottom: 1px solid #fff; /* Linha abaixo do título */
  padding-bottom: 10px; /* Espaçamento abaixo do texto */
}

/* Estilo específico para "Clientes que faltam pagar" */
.clients-title {
  border-bottom: 1px solid #fff; /* Linha abaixo do título */
  padding-bottom: 10px; /* Espaçamento abaixo do texto */
}

.faturamento {
  font-size: 1.1em; /* Tamanho da fonte */
  font-weight: bold; /* Negrito */
  color: #151f28; /* Cor do texto */
  text-align: center; /* Centraliza o texto */
  margin: 20px 0; /* Margem acima e abaixo */
  padding: 10px; /* Preenchimento interno */
  background-color: #fff; /* Cor de fundo suave */
  border-radius: 5px; /* Cantos arredondados */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Sombra leve */
  display: flex;
  align-items: center;
  justify-content: center;
}

.revenue-icon {
  color: #151f28;
  background: #fff;
  border: none;
  border-radius: 50px;
  font-size: 17px;
  padding: 0px;
  margin-left: 10px;
}
