.classes-container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  min-height: 100vh;
  background-color: #151f28;
  text-align: center;
  font-family: Arial, sans-serif;
  padding: 22px;
}

.button-bar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.icon {
  cursor: pointer;
  font-size: 20px;
  padding: 10px;
}

.schedule-form {
  border: 1px solid #151f28;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  background-color: rgba(255, 255, 255, 0.1);
}

.schedule-grid {
  display: flex;
  gap: 20px;
  margin-bottom: 15px;
}

.schedule-grid label {
  display: flex;
  flex-direction: column;
}

.schedule-grid select {
  margin-top: 5px;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.students-search {
  position: relative;
}

.students-search input {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #151f28;
  font-size: 1rem;
  width: 100%;
}

.students-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
}

.student-item {
  padding: 10px;
  cursor: pointer;
}

.student-item:hover {
  background-color: #f0f0f0;
}

.selected-students {
  margin-top: 15px;
  list-style-type: none;
  padding: 0;
}

.selected-students li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #151f28;
  color: #fff;
}

.remove-student-icon {
  cursor: pointer;
  color: #fff;
}

.schedule-item {
  border: 1px solid #151f28;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  background-color: rgba(255, 255, 255, 0.1);
}

.schedule-item h3 {
  text-align: center; /* Centraliza o título do dia */
  margin: 0;
  margin-bottom: 10px;
  color: #fff;
}

.schedule-item div {
  border-bottom: 1px solid #151f28; /* Linha de separação entre horários */
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.schedule-item h4 {
  text-align: center; /* Centraliza o título do horário */
  margin: 0;
  margin-bottom: 5px;
  color: #fff;
}

.schedule-item ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: center; /* Centraliza a lista de alunos */
  color: #fff;
}

.schedule-item li {
  display: inline-block; /* Exibe os alunos em linha */
  margin: 5px 0;
  padding: 5px;
  /* border-bottom: 1px solid #151f28; */
}

.schedule-grid {
  flex-direction: column;
}

.student-label {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 15px;
  color: #fff;
}

.times-label {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 15px;
  color: #fff;
}

.full-message {
  color: red;
  font-weight: bold;
  margin-left: 5px;
}

.students-total {
  font-size: 1em; /* Tamanho da fonte */
  font-weight: bold; /* Negrito */
  color: #151f28; /* Cor do texto */
  text-align: center; /* Centraliza o texto */
  margin: 20px 0; /* Margem acima e abaixo */
  padding: 10px; /* Preenchimento interno */
  background-color: #fff; /* Cor de fundo suave */
  border-radius: 5px; /* Cantos arredondados */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Sombra leve */
  display: flex;
  align-items: center;
  justify-content: center;
}
