.students-container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  min-height: 100vh;
  background-color: #151f28;
  text-align: center;
  font-family: Arial, sans-serif;
  padding: 22px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  margin-bottom: 5px;
  color: #fff;
}

.form-group input {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #151f28;
  font-size: 1rem;
  width: 100%;
}

.form-group input:focus {
  border-color: #151f28;
  outline: none;
}

.students-main {
  display: flex;
  flex-direction: column;
}

.button-bar {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-bottom: 20px;
}

.icon {
  cursor: pointer;
  font-size: 20px;
  padding: 10px;
}

.save-icon {
  background: #151f28;
  color: #fff;
  border: none;
  border-radius: 50px;
}

.cancel-icon {
  background: #151f28;
  color: #fff;
  border: none;
  border-radius: 50px;
}

.edit-icon {
  color: #fff;
  cursor: pointer;
  font-size: 16px;
}

.add-icon {
  background: #151f28;
  color: #fff;
  border: none;
  border-radius: 50px;
}

.students-form {
  display: flex;
  flex-direction: column;
  border: 1px solid #151f28;
  border-radius: 4px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.1);
}

.students-list ul {
  margin-top: 20px;
  list-style: none;
  padding: 0;
}

.students-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 0;
  border-bottom: 1px solid #fff;
}

.students-list li p {
  flex-grow: 1;
  text-align: left;
  margin: 0;
}

.delete-icon {
  color: #fff;
  cursor: pointer;
  font-size: 16px;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
}

.pagination button.active {
  background-color: #151f28;
  color: white;
}

.pagination button:hover {
  background-color: #e2e6ea;
}

.students-search input {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #151f28;
  font-size: 1rem;
  width: 100%;
}

.students-search {
  margin-top: 20px;
  margin-bottom: 10px;
}

.student-details {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #151f28;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.1);
}

.student-details h3 {
  margin-top: 0;
}

.student-details p {
  margin: 5px 0;
}

.students-list p {
  cursor: pointer;
}

.students-list p:hover {
  text-decoration: underline;
}

.students-list-title {
  font-size: 1.1rem; /* Tamanho da fonte */
  font-weight: bold; /* Negrito */
  color: #fff; /* Cor do texto */
  margin: 20px 0; /* Margem superior e inferior */
  text-align: center; /* Alinhamento do texto */
}
