.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #151f28;
  text-align: center;
  font-family: Arial, sans-serif;
  padding: 0 20px;
  color: white;
}

.admin-access {
  margin: 10px 0;
}

.login__title {
  margin: 20px 0; /* Espaçamento entre o título e os campos */
}

.form-label {
  margin: 5px 0;
}

.input-field {
  padding: 10px;
  border: none;
  border-radius: 5px;
  margin-bottom: 15px;
  width: 80%;
  max-width: 390px; /* Define uma largura máxima */
  box-sizing: border-box;
}

.loginButton {
  margin-top: 20px;
  display: block;
  padding: 10px 50px;
  color: #151f28;
  text-decoration: none;
  transition: background-color 0.3s ease;
  background-color: #ffffff;
  margin-bottom: 60px;
  border-radius: 100px;
}

.loginButton:hover {
  background-color: #e0e0e0;
  color: #151f28;
}
