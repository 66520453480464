.schedules-container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  min-height: 100vh;
  background-color: #151f28;
  text-align: center;
  font-family: Arial, sans-serif;
  padding: 22px;
}

.schedules-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.schedules-nav h2 {
  margin: 0;
}

.schedules-main {
  display: flex;
  flex-direction: column;
}

.schedules-title {
  text-align: center;
  margin-bottom: 20px;
}

.schedules-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.schedule-day {
  border: 1px solid #151f28;
  border-radius: 10px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.1);
}

.day-label {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 15px;
}

.select-wrapper {
  margin-bottom: 20px;
}

.select-wrapper label {
  display: block;
  margin-bottom: 5px;
}

.select-wrapper select,
.time-selector {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #151f28;
  font-size: 1rem;
}

.time-slot {
  margin-top: 5px;
  border-top: 1px solid #151f28;
  padding-top: 5px;
}

.time-slot .select-wrapper {
  margin-bottom: 5px;
}

.time-selector {
  font-size: 1rem;
  /* Ensure the time selector fits well within the design */
}

.time-slot-header {
  text-align: center;
}

.time-selectors {
  display: flex;
  justify-content: center;
  gap: 20px; /* Ajuste o espaço entre os DatePickers conforme necessário */
}

.select-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.select-wrapper label {
  margin-bottom: 5px; /* Espaço entre o label e o DatePicker */
}

.time-selector {
  width: 100%; /* Ajuste a largura do DatePicker conforme necessário */
  max-width: 150px; /* Limite a largura máxima para evitar que fique muito largo */
}

.button-bar {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-bottom: 20px;
}

.icon {
  cursor: pointer;
  font-size: 20px;
  padding: 10px;
}

.save-icon {
  background: #151f28;
  color: #fff;
  border: none;
  border-radius: 50px;
}

.cancel-icon {
  background: #151f28;
  color: #fff;
  border: none;
  border-radius: 50px;
}
